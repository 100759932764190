import {
  ClipboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import omitBy from 'lodash/fp/omitBy'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import overSome from 'lodash/overSome'
import { ParsedUrlQuery } from 'querystring'
import {
  InternalNavBar_ActivityFragment,
  JobType,
  UserType,
} from 'generated/graphql'
import { NavBarActiveKey } from 'app.layout/constant/pageLayout.type'
import {
  MobileHeightMap,
  MobileRenderType,
  RenderComponentType,
} from 'components/PageLayout'
import { ActivityTypeID, RecruitScaleType } from 'constants/enums'
import { COPY_SUFFIX } from './config'

export const formatPeriodDate = (
  date: Date | number | undefined | null,
): string => {
  if (!date) {
    return ''
  }

  const formattedDate = new Date(date)

  const yy = formattedDate.getFullYear() % 100
  const mm = formattedDate.getMonth() + 1
  const dd = formattedDate.getDate()

  return `${yy}.${mm}.${dd}`
}

export const getPeriodDate = (
  startAt: Date | number | undefined | null,
  endAt: Date | number | undefined | null,
) => {
  if (!startAt && !endAt) {
    return '기간 없음'
  }

  return `${formatPeriodDate(startAt)} ~ ${formatPeriodDate(endAt)}`
}

export const formatDateYY_MM_DD = (
  date: Date | number | undefined | null,
): string => {
  if (!date) {
    return ''
  }

  const formattedDate = new Date(date)

  const yy = formattedDate.getFullYear()
  const mm = formattedDate.getMonth() + 1
  const dd = formattedDate.getDate()

  return `${yy}.${mm}.${dd}`
}

export function useFallbackImageInSSR(fallbackSrc: string) {
  const ref = useRef<HTMLImageElement>(null)

  /**
   * Error happened / catched after hydration
   */
  const onError = useCallback(
    (e) => {
      e.target.src = fallbackSrc
    },
    [fallbackSrc],
  )

  /**
   * Error happened before hydration, but catched after hydration
   */
  useEffect(() => {
    if (ref && ref.current) {
      const { complete, naturalHeight } = ref.current
      const errorLoadingImgBeforeHydration = complete && naturalHeight === 0

      if (errorLoadingImgBeforeHydration) {
        ref.current.src = fallbackSrc
      }
    }
  }, [fallbackSrc])

  return {
    ref,
    onError,
  }
}

export const shortenText = (text: string, length: number): string => {
  if (text.length > length) {
    return `${text.substr(0, length)}...`
  }

  return text
}

export const scrollToRef = (ref: RefObject<any>, offset: number) =>
  window.scrollTo(0, ref.current.offsetTop - offset)

export const jobTypeToString = (jobType?: JobType): string => {
  switch (jobType) {
    case JobType.NEW: {
      return '신입'
    }
    case JobType.INTERN: {
      return '인턴'
    }
    case JobType.EXPERIENCED: {
      return '경력직'
    }
    case JobType.CONTRACT: {
      return '계약직'
    }
    default: {
      throw new Error('job type is not valid.')
    }
  }
}

export const getJobType = (jobTypes: JobType[]): string => {
  const jobs = jobTypes.map((jobType) => jobTypeToString(jobType))
  return jobs?.join(', ')
}

export const getRecruitScale = (
  recruitScale?: RecruitScaleType | string | null,
): string => {
  switch (recruitScale) {
    case undefined:
    case null:
    case RecruitScaleType.ONE_DIGIT: {
      return '0명'
    }
    case RecruitScaleType.TEN_DIGIT: {
      return '00명'
    }
    case RecruitScaleType.HUNDRED_DIGIT: {
      return '000명'
    }
    default: {
      return `${recruitScale}명`
    }
  }
}

interface ValuesFromActivityType {
  activity: any
  contest: any
  club: any
  intern?: any
  recruit: any
  education?: any
}

export const getValueFromActivityType = (
  activityTypeID: ActivityTypeID | null | undefined,
  values: ValuesFromActivityType,
) => {
  const navigate: {
    [key in ActivityTypeID]: any
  } = {
    [ActivityTypeID.ACTIVITY]: values.activity,
    [ActivityTypeID.CONTEST]: values.contest,
    [ActivityTypeID.CLUB]: values.club,
    [ActivityTypeID.INTERN]: values.intern,
    [ActivityTypeID.RECRUIT]: values.recruit,
    [ActivityTypeID.EDUCATION]: values.education,
  }

  if (!activityTypeID) {
    throw new Error('Activity type is not valid.')
  }

  return navigate[activityTypeID]
}

export const formatPhoneNumber = (phoneNumber: string) => {
  const matched = phoneNumber.match(/^(010)(\d{4})(\d{4})$/)

  if (matched) {
    return matched[1] + '-' + matched[2] + '-' + matched[3]
  }
}

const filterEmpty = (value: any) => {
  if (!isNumber(value) && !isBoolean(value)) {
    return isEmpty(value)
  }

  return false
}

export const filterEmptyField = omitBy(overSome([isNil, filterEmpty]))

export const filterArrayEmpty = (arr: any[]): any[] => {
  const filterArray = overSome([isNil, filterEmpty])
  const filteredArray = arr.filter((it: any) => !filterArray(it))
  return filteredArray
}

interface parseValuesOptions {
  parseNumbers?: boolean
  parseBooleans?: boolean
}

// https://github.com/sindresorhus/query-string/blob/7712622f5ae8cc1f99ae45f4af1a1965efec44ac/index.js
export const parseValues = (values: any, options?: parseValuesOptions): any => {
  if (!options) return values

  for (const k of Object.keys(values)) {
    let value = values[k]

    if (
      options.parseNumbers &&
      !Number.isNaN(Number(value)) &&
      typeof value === 'string' &&
      value.trim() !== ''
    ) {
      value = Number(value)
    } else if (
      options.parseBooleans &&
      value !== null &&
      (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')
    ) {
      value = value.toLowerCase() === 'true'
    }

    values[k] = value
  }

  return values
}

export const bfs = (
  root: any,
  childProp: string,
  visit: any,
  depth?: number, // root is 0
) => {
  const q: any[] = []
  let node
  let currDepth = 0

  q.push(root)
  q.push(null)

  while (q.length !== 0) {
    node = q.shift()

    if (!node) {
      currDepth += 1
      q.push(null)

      if (!q[0]) break
      continue
    }

    if (depth !== 0 && !depth) {
      visit(node)
    } else {
      currDepth === depth && visit(node)
    }

    const children = node[childProp]

    for (let i = 0; i < children?.length; i += 1) {
      q.push(children[i])
    }
  }
}

export const copySourceToClipboard = (
  event: ClipboardEvent<HTMLDivElement>,
) => {
  event.stopPropagation()
  event.preventDefault()

  const span = document.createElement('SPAN')
  const content = window.getSelection()?.getRangeAt(0).cloneContents()

  if (content) {
    span.appendChild(content)

    event.clipboardData.setData('Text', span.textContent + '\n\n' + COPY_SUFFIX) // ie
    event.clipboardData.setData(
      'text/html',
      span.innerHTML + '<br /><br />' + COPY_SUFFIX,
    )
    event.clipboardData.setData(
      'text/plain',
      span.textContent + '\n\n' + COPY_SUFFIX,
    )
  }
}

export function numberWithCommas(count: number) {
  return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const NATIVE_TAGS = [
  'address',
  'article',
  'aside',
  'blockquote',
  'details',
  'dialog',
  'dd',
  'div',
  'dl',
  'dt',
  'fieldset',
  'figcaption',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hgroup',
  'hr',
  'li',
  'main',
  'nav',
  'ol',
  'p',
  'pre',
  'section',
  'table',
  'ul',
  'a',
  'abbr',
  'acronym',
  'audio',
  'b',
  'bdi',
  'bdo',
  'big',
  'br',
  'button',
  'canvas',
  'cite',
  'code',
  'data',
  'datalist',
  'del',
  'dfn',
  'em',
  'embed',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'map',
  'mark',
  'meter',
  'noscript',
  'object',
  'output',
  'picture',
  'progress',
  'q',
  'ruby',
  's',
  'samp',
  'script',
  'select',
  'slot',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'svg',
  'template',
  'textarea',
  'time',
  'u',
  'tt',
  'var',
  'video',
  'wbr',
]

export function isValidTag(tagName: string) {
  return NATIVE_TAGS.includes(tagName)
}

export function isTag(tag: string) {
  return /(<([^>]+)>)/gi.test(tag)
}

export function getCloseTagOf(tag: string) {
  if (tag.charAt(1) === '/') {
    return tag
  }

  return `${tag.substring(0, 1)}/${tag.substring(1, tag.length)}`
}

export function getSelectionText() {
  const selection = window.getSelection()

  if (!selection) {
    return ''
  }

  return selection?.toString()
}

export const formatBirthday = (birthday: string): string => {
  let result = birthday
  const originNumber = birthday.replace(/-/gi, '')
  if (originNumber.length === 6 && birthday.length === 6) {
    result = originNumber.replace(/(\d{4})(\d{2})/, '$1-$2-')
  }
  if (originNumber.length === 7 && birthday.length === 8) {
    result = originNumber.replace(/(\d{4})(\d{2})(\d{1})/, '$1-$2-$3')
  }
  if (originNumber.length === 8 && birthday.length === 8) {
    result = originNumber.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
  }

  if (birthday.length > 10) {
    result = birthday.substring(0, 10)
  }

  return result
}

export const formatNumberComma = (count: number) => {
  return new Intl.NumberFormat('ko-KR').format(count)
}

export const getMobileSkickyHeights = (
  mobileStickyComponents: Array<MobileRenderType>,
) => {
  const mobileHeightList: Array<{
    component: MobileRenderType
    height: number
  }> = [
    { component: 'Header', height: 50 },
    { component: 'MobileSearchBar', height: 60 },
    { component: 'MobileNavTab', height: 50 },
  ]
  const heightMap: MobileHeightMap = {}

  for (const targetComponent of mobileStickyComponents) {
    let height = 0
    for (
      let index = 0;
      index < mobileHeightList.length &&
      mobileHeightList[index].component !== targetComponent;
      index++
    ) {
      const { component, height: componentHeight } = mobileHeightList[index]
      if (mobileStickyComponents.includes(component)) {
        height += componentHeight
      }
    }
    heightMap[targetComponent] = height
  }

  return heightMap
}

export const getComponentVisible = (
  renderComponents: RenderComponentType[],
) => {
  const componentVisible: { [key in RenderComponentType]: boolean } = {
    ExternalNavBar: false,
    Header: false,
    SearchBar: false,
    InternalNavBar: false,
    MobileSearchBar: false,
    MobileNavTab: false,
    Footer: false,
  }

  renderComponents.forEach((component) => {
    componentVisible[component] = true
  })

  return componentVisible
}

export const getStickyComponent = (
  mobileStickyComponents: MobileRenderType[],
) => {
  const isStickyComponent: { [key in MobileRenderType]: boolean } = {
    Header: false,
    MobileSearchBar: false,
    MobileNavTab: false,
  }

  mobileStickyComponents.forEach((component) => {
    isStickyComponent[component] = true
  })

  return isStickyComponent
}

export const getCurrentTab = (
  pathname: string,
  query: ParsedUrlQuery,
  activity?: InternalNavBar_ActivityFragment | null,
): NavBarActiveKey => {
  if (pathname === '/') return NavBarActiveKey.ROOT
  if (pathname.match(/^\/channel.*/)) return NavBarActiveKey.CHANNEL
  if (pathname.match(/^\/calendar.*/)) {
    if (query.parentActiveTab === 'activity') {
      return NavBarActiveKey.CALENDAR_ACTIVITY
    }
    return NavBarActiveKey.CALENDAR_RECRUIT
  }
  if (pathname.match(/^\/cover-letter.*/)) return NavBarActiveKey.COVER_LETTER
  if (pathname.match(/^\/self-introduction.*/))
    return NavBarActiveKey.SELF_INTRODUCTION
  if (pathname === '/list/intern') return NavBarActiveKey.INTERN
  if (pathname === '/list/recruit') return NavBarActiveKey.RECRUIT
  if (pathname === '/list/activity') return NavBarActiveKey.ACTIVITY
  if (pathname === '/list/contest') return NavBarActiveKey.CONTEST
  if (pathname === '/list/club') return NavBarActiveKey.CLUB
  if (pathname === 'list/education') NavBarActiveKey.EDUCATION
  if (pathname.match(/^\/activity.*/)) {
    const isIntern = activity?.jobTypes?.includes(JobType.INTERN)

    if (isIntern && activity?.type === ActivityTypeID.RECRUIT)
      return NavBarActiveKey.INTERN
    if (!isIntern && activity?.type === ActivityTypeID.RECRUIT)
      return NavBarActiveKey.RECRUIT
    if (activity?.type === ActivityTypeID.ACTIVITY)
      return NavBarActiveKey.ACTIVITY
    if (activity?.type === ActivityTypeID.CONTEST)
      return NavBarActiveKey.CONTEST
    if (activity?.type === ActivityTypeID.CLUB) return NavBarActiveKey.CLUB
  }
  return NavBarActiveKey.ROOT
}

export const getIsChannelUser = (currentUser) => {
  return currentUser && currentUser.type !== UserType.NORMAL
}
