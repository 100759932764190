import { useRef, useState } from 'react'
import styled from 'styled-components'
import { useOutSideClick } from 'hooks/useOutSideClick'
import ExpandMoreIcon from 'svgIcons/ExpandMore'
import ActivityTypeDropdown from './dropdown/ActivityTypeDropdown.desktop'

const LiveActivityListTitleDesktop = () => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false)
  const dropdownRef = useRef<HTMLUListElement>(null)

  const handleToggleDropdown = () => {
    setIsDropDownVisible((prev) => !prev)
  }

  useOutSideClick({
    ref: dropdownRef,
    isActive: isDropDownVisible,
    onOutsideClick: handleToggleDropdown,
  })

  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 활동정보</h2>
      <button onClick={handleToggleDropdown} className="menu-button">
        전체보기
        <ExpandMoreIcon />
      </button>
      {isDropDownVisible && <ActivityTypeDropdown ref={dropdownRef} />}
    </StyledWrapper>
  )
}

export default LiveActivityListTitleDesktop

const StyledWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 !important;
  .title-text {
    font-size: 20px;
    font-weight: bold;
  }
  .menu-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.63;
    letter-spacing: -0.64px;
    gap: 5px;
    cursor: pointer;
    svg {
      width: 14px;
    }
  }
`
